import { GatsbySeo } from 'gatsby-plugin-next-seo'
import RecruitingSuppliersComponent from 'src/components/Institucional/RecruitingSuppliers'

function RecruitingSuppliersPage() {
  return (
    <>
      <GatsbySeo title="Institucional | Decathlon" noindex nofollow />
      <RecruitingSuppliersComponent />
    </>
  )
}

export default RecruitingSuppliersPage
